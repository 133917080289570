import React, { FC } from "react";
import SectionGridFilterCard from "./SelectionGridFilterCard";
import PromoBlock from "./PromoBlock/index";
import IntroBlock from "./IntroBlock/index";
import ProductType from "./ProductType";
export interface ProductsPageProps {
	className?: string;
};

const ProductsPage: FC<ProductsPageProps> = ({ className = "" }) => {

	return (
		<div className={`nc-ProductsPage relative ${className}`}>
			{/* 视频 */}
			<IntroBlock />
			{/* 商品分类 */}
			<ProductType />
			{/* 商品卡片 */}
			<SectionGridFilterCard />
			{/* 视频 */}
			<PromoBlock heading={'Promotions'} leftIsImage={true} />
		</div>
	)
};

export default ProductsPage;