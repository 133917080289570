import React, { useState, useEffect } from "react";

//API
import Axios from "axios";
import { url } from "../utils/constant";

interface AnyInterface {
	cart: any[];
	apiBreakdown: any[];
	total: number;
	responseTotal: number;
	subtotal: number;
	deliveryOptions: { [key: string]: number };
	discountCodes: any[];
	inputDiscountCode: string;
	discountByAmount: boolean;
	discountByPercentage: boolean;
	displayDiscount: string | number;
	shippingFee: string | number;
	phone: string;
	cantUseCouponMsg: string;
	setPhone: (phone: any) => void;
	setCantUseCouponMsg: (cantUseCouponMsg: any) => void;
	handleDiscount: (e: any) => void;
	handlePhone: (e: any) => void;
	addToCart: (products: any) => void;
	increaseAmount: (skuId: number, productId: number) => void;
	decreaseAmount: (skuId: number, productId: number) => void;
	clearCart: () => void;
	setDiscountCodes: (discountOptions: any) => void;
	setDeliveryOptions: (deliveryOptions: any) => void;
	setShippingFee: (shippingFee: any) => void;
	setDisplayDiscount: (displayDiscount: any) => void;
	setDiscountByAmount: (discountByAmount: any) => void;
	setDiscountByPercentage: (discountByPercentage: any) => void;
}

const defaultState = {
	cart: [],
	apiBreakdown: [],
	total: 0,
	responseTotal: 0,
	subtotal: 0,
	deliveryOptions: {
		freeDeliveryAmount: 0,
		deliveryFee: 0,
		waiveDelivery: 0,
	},
	paymentSent: false,
	discountCodes: [],
	inputDiscountCode: "",
	discountByAmount: false,
	discountByPercentage: false,
	displayDiscount: "",
	shippingFee: "",
	phone: "",
	cantUseCouponMsg: "",
	setPhone: () => { },
	setCantUseCouponMsg: () => { },
	handleDiscount: () => { },
	handlePhone: () => { },
	setPaymentSent: () => { },
	addToCart: () => { },
	increaseAmount: () => { },
	decreaseAmount: () => { },
	clearCart: () => { },
	setDeliveryOptions: () => { },
	setDiscountCodes: () => { },
	setShippingFee: () => { },
	setDisplayDiscount: () => { },
	setDiscountByAmount: () => { },
	setDiscountByPercentage: () => { },
};

const CartContext = React.createContext<AnyInterface>(defaultState);

const CartProvider: React.FC = ({ children }) => {
	const [cart, setCart] = useState<Array<object>>([]);
	const [apiBreakdown, setApiBreakdown] = useState<Array<object>>([]);
	const [total, setTotal] = useState<number>(0);
	const [subtotal, setSubtotal] = useState<number>(0);
	const [responseTotal, setResponseTotal] = useState<number>(0);
	const [deliveryOptions, setDeliveryOptions] = useState({
		freeDeliveryAmount: 0,
		deliveryFee: 0,
		waiveDelivery: 0,
	});
	const [discountCodes, setDiscountCodes] = useState<Array<object>>([]);
	const [inputDiscountCode, setInputDiscountCode] = useState<string>("");
	const [discountByAmount, setDiscountByAmount] = useState<boolean>(false);
	const [discountByPercentage, setDiscountByPercentage] = useState<boolean>(false);
	const [displayDiscount, setDisplayDiscount] = useState<string | number>("");
	const [shippingFee, setShippingFee] = useState<string | number>("");
	const [phone, setPhone] = useState<string>("");
	const [cantUseCouponMsg, setCantUseCouponMsg] = useState<string>("");

	const getApiTotal = async (cart: any, couponCode: any, phone: any) => {
		try {
			const skuList = cart.reduce((p: any[], v: any) => {
				if (Array.isArray(v.selectedSkuList)) {
					const selectedSkuList = v.selectedSkuList.filter((n: any) => n.count > 0);
					if (selectedSkuList.length > 0) {
						selectedSkuList.forEach((sku: any) => {
							p.push({ skuId: sku.id, count: sku.count });
						});
					}
				}
				return p;
			}, []);
			if (skuList.length === 0) {
				setApiBreakdown([]);
				setResponseTotal(0);
				return;
			}

			const result = await Axios.post(`${url}/rapidcart/web/receipt/calculate`, { skuList, couponCode, phone });
			// console.log("Calculate Result:", result.data.data);
			// let transactionTotal = result.data.data.transactionTotal;
			// let originalTotal = result.data.data.originTransactionTotal;
			let subSum = result.data.data.originTransactionTotal;
			let sum = result.data.data.transactionTotal;
			let displayDis = result.data.data.couponReduceAmount;
			let shipping = result.data.data.deliveryFee;
			let breakdown = result.data.data.cart;
			let reason = result.data.data.reasonsForNotUsingCoupons;

			if (displayDis > 0) {
				setDiscountByAmount(true);
			} else {
				setDiscountByAmount(false);
			}

			if (reason !== "") {
				setCantUseCouponMsg(reason);
			} else {
				setCantUseCouponMsg("");
			}

			setShippingFee(shipping);
			setSubtotal(subSum);
			setTotal(sum);
			setDisplayDiscount(displayDis);
			setApiBreakdown(breakdown);
			// setResponseTotal(originalTotal);
		} catch (err) {
			console.log("Calculate Error:", err);
		}
	};

	// const productBreakdown = (cart: any) => {
	//   cart.forEach((cartItem: any) => {
	//     apiBreakdown.forEach((breakdownItem: any) => {
	//       if (cartItem.id === breakdownItem.inventoryId) {
	//         return (cartItem.subTotal = breakdownItem.subTotal);
	//       }
	//     });
	//   });
	// };

	useEffect(() => {
		const localData = window.localStorage.getItem("CURRENT-CART");
		const localDiscount = window.localStorage.getItem("CURRENT-DISCOUNT");
		const localDelivery = window.localStorage.getItem("CURRENT-DELIVERY");
		const localCustomerPhone = window.localStorage.getItem("CUSTOMER-PHONE");

		if (localDiscount !== null) {
			setDiscountCodes(JSON.parse(localDiscount));
		}

		if (localDelivery !== null) {
			setDeliveryOptions(JSON.parse(localDelivery));
		}

		if (localData !== null) {
			setCart(JSON.parse(localData) || []);
		}

		if (localCustomerPhone !== null) {
			setPhone(localCustomerPhone);
		}
	}, []);

	useEffect(() => {
		if (!Array.isArray(cart) || !cart.length) {
			return;
		}
		getApiTotal(cart, inputDiscountCode, phone);
	}, [cart, inputDiscountCode, phone]);

	// useEffect(() => {
	//   let total = responseTotal;

	//   productBreakdown(cart);

	//   const { deliveryFee, freeDeliveryAmount } = deliveryOptions;

	//   let amountOffTotal;
	//   let percentOffTotal;

	//   if (discountCodes.length > 0 && inputDiscountCode !== "") {
	//     setDisplayDiscount("");
	//     setDiscountByAmount(false);
	//     setDiscountByPercentage(false);

	//     discountCodes.forEach((item: any) => {
	//       if (inputDiscountCode === item.discountCode) {
	//         if (item.discountAmount) {
	//           setDisplayDiscount(item.discountAmount);
	//           setDiscountByAmount(true);
	//           amountOffTotal = parseInt(item.discountAmount);
	//         } else {
	//           setDisplayDiscount(item.discountPercentage);
	//           setDiscountByPercentage(true);
	//           let decimalValue = parseInt(item.discountPercentage) / 100;
	//           percentOffTotal = 1 - decimalValue;
	//         }
	//       }
	//     });
	//   }

	//   if (total > freeDeliveryAmount || total === freeDeliveryAmount) {
	//     // console.log('Free delivery')
	//     setShippingFee("0");
	//     setSubtotal(total);

	//     if (amountOffTotal !== undefined) {
	//       setTotal(total - amountOffTotal);
	//     } else if (percentOffTotal !== undefined) {
	//       setTotal(total * percentOffTotal);
	//     } else {
	//       setDiscountByAmount(false);
	//       setDiscountByPercentage(false);
	//       setTotal(total);
	//     }
	//   } else {
	//     // console.log('No Free delivery')
	//     let calculatedSub: any;

	//     if (amountOffTotal !== undefined) {
	//       calculatedSub = total - amountOffTotal;
	//     } else if (percentOffTotal !== undefined) {
	//       calculatedSub = total * percentOffTotal;
	//     } else {
	//       setDiscountByAmount(false);
	//       setDiscountByPercentage(false);
	//       calculatedSub = total;
	//     }
	//     setSubtotal(total);
	//     let shipping = deliveryFee.toString();
	//     setShippingFee(shipping);

	//     let newTotal = calculatedSub + deliveryFee;
	//     setTotal(newTotal);
	//   }
	// }, [cart, inputDiscountCode, deliveryOptions, responseTotal]);

	useEffect(() => {
		window.localStorage.setItem("CURRENT-CART", JSON.stringify(cart));
	}, [cart]);

	useEffect(() => {
		window.localStorage.setItem("CUSTOMER-PHONE", phone);
	}, [phone]);

	const increaseAmount = (skuId: number, productId: number) => {
		const productIndex = cart.findIndex((n: any) => n.id === productId);
		// console.log('[decreaseAmount]', skuId, productId);
		if (productIndex === -1) {
			return;
		}
		const productInfo: any = cart[productIndex];
		const { selectedSkuList } = productInfo;
		const skuIndex = selectedSkuList.findIndex((n: any) => n.id === skuId);
		const newCart = [...cart];
		if (skuIndex !== -1) {
			// @ts-ignore
			newCart[productIndex].selectedSkuList[skuIndex].count += 1;
		}
		setCart(newCart);
	};

	const decreaseAmount = (skuId: number, productId: number) => {
		const productIndex = cart.findIndex((n: any) => n.id === productId);
		// console.log('[decreaseAmount]', skuId, productId);
		if (productIndex === -1) {
			return;
		}
		const productInfo: any = cart[productIndex];
		const { selectedSkuList } = productInfo;
		const skuIndex = selectedSkuList.findIndex((n: any) => n.id === skuId);
		const newCart = [...cart];
		if (skuIndex !== -1 && selectedSkuList[skuIndex].count >= 1) {
			// @ts-ignore
			newCart[productIndex].selectedSkuList[skuIndex].count -= 1;
		}

		newCart.forEach((item: any, index: any) => {
			let skuList = item.selectedSkuList;
			let allZero = skuList.every((skuItem: any) => skuItem.count === 0);

			if (allZero) {
				newCart.splice(index, 1);
			}
		});

		setCart(newCart);
	};

	const addToCart = (products: any) => {
		const { title, price, id, seq, skuId, discountRules, selectedSkuList, pictureUrls } = products;

		const index = cart.findIndex((item: any) => item.id === id);
		if (index !== -1) {
			const newCart = [...cart].map((item: any) => {
				return {
					...item,
					selectedSkuList: item.id === id ? selectedSkuList : item.selectedSkuList,
				};
			});

			setCart(newCart);
		} else {
			let remarks = "";

			const cartItems = [
				...cart,
				{
					id,
					seq,
					title,
					price,
					remarks,
					amount: 1,
					subTotal: 0,
					skuId,
					count: 1,
					discountRules,
					selectedSkuList,
					pictureUrls,
				},
			];

			setCart(cartItems);
		}
	};

	const clearCart = () => {
		window.localStorage.clear();
		setCart([]);
	};

	const handleDiscount = (e: any) => {
		setInputDiscountCode(e.target.value);
	};

	const handlePhone = (e: any) => {
		setPhone(e.target.value);
	};

	return (
		<CartContext.Provider
			value={{
				cart,
				apiBreakdown,
				total,
				responseTotal,
				subtotal,
				deliveryOptions,
				discountCodes,
				inputDiscountCode,
				discountByAmount,
				discountByPercentage,
				displayDiscount,
				shippingFee,
				phone,
				cantUseCouponMsg,
				setPhone,
				setCantUseCouponMsg,
				addToCart,
				increaseAmount,
				decreaseAmount,
				clearCart,
				handleDiscount,
				handlePhone,
				setDeliveryOptions,
				setDiscountCodes,
				setShippingFee,
				setDisplayDiscount,
				setDiscountByAmount,
				setDiscountByPercentage,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export { CartProvider, CartContext };
