import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import Heading2 from "components/Heading/Heading2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { commonAxiosPostRequest, commonAxiosGetRequest } from "utils/request";
import { getPureArray, getKeyByLang } from "utils/util";

export interface PageAboutProps {
	className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const { language } = i18n;
	const [configInfo, setConfigInfo] = useState<any>({});

	const handleQueryConfigInfo = async () => {
		const [error, response] = await commonAxiosPostRequest<{ config: any }, {}>('/universal_mall/api/app/common/config', {});
		const config = response?.config || {};
		setConfigInfo(config);
	}

	useEffect(() => {
		handleQueryConfigInfo();
	}, []);

	const refundPolicyContent = configInfo?.[getKeyByLang(language, 'refundPolicyContent')] || configInfo?.refundPolicyContent || '';
	const deliveryPolicyContent = configInfo?.[getKeyByLang(language, 'deliveryPolicyContent')] || configInfo?.deliveryPolicyContent || '';

	return (
		<div className={`nc-PageAbout overflow-hidden relative ${className}`}>
			<BgGlassmorphism />
			<div className="container py-8 lg:py-16 space-y-16 lg:space-y-28">
				<Heading2 heading={t('nav.delivery_policy')} id="deliveryPolicy" />
				<div dangerouslySetInnerHTML={{ __html: deliveryPolicyContent || '' }}></div>
				<Heading2 heading={t('nav.refund_policy')} />
				<div dangerouslySetInnerHTML={{ __html: refundPolicyContent || '' }}></div>
			</div>
		</div>
	);
};

export default PageAbout;
