import React, { FC, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Carousel } from 'antd';
import { commonAxiosPostRequest, commonAxiosGetRequest } from "utils/request";
import { getPureArray, getCurrentPage } from "utils/util";


export interface CarouselProps {
    className?: string;
};

interface TBannerItem {
    id: string;
    pictureUrl: string;
    linkUrl: string;
};

const CarouselComponent: FC<CarouselProps> = ({ className = "" }) => {
    const location = useLocation();
    const [bannerList, setBannerList] = useState<TBannerItem[]>([]);

    const handleQueryBannerList = async () => {
        const { page, id } = getCurrentPage();
        const [error, response] = await commonAxiosPostRequest<{ list: TBannerItem[] }, {}>('/universal_mall/api/app/common/bannerList', {
            pages: page,
            type: 1,
            productTypeId: page === 'category' ? id : null,
        });
        const list = getPureArray<TBannerItem>(response?.list);
        setBannerList(list);
    }

    const onChange = (currentSlide: number) => {
        // console.log(currentSlide);
    };

    const onBannerItemClick = (item: any) => {
        if (item.linkUrl) {
            window.location.href = item.linkUrl;
        }
    };

    useEffect(() => {
        handleQueryBannerList();
    }, [location]);

    if (!bannerList.length) {
        return null;
    }

    return (
        <div className="max-w-[1440px] mx-auto mt-8 sm:mt-8 lg:mt-12">
            <Carousel afterChange={onChange} effect="fade" arrows={true} draggable={true} autoplay={true} >
                {
                    bannerList.map(item => (
                        <div key={item.id} className="w-full" onClick={() => onBannerItemClick(item)}>
                            <img src={item.pictureUrl} alt={item.id} className="w-full h-[auto] max-h-[618px]" />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
};

export default CarouselComponent;