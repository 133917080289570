import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { getKeyByLang, getNameByLang } from "utils/util";
import noImage from "images/404.png";
import { AuthContext } from "context/authenticate";


function CheckoutSummaryRender(props: any) {
    const { cartSkuList } = props;
    const { language } = i18n;

    const getSkuTotalPrice = (sku: any) => {
        return +((Number(sku.sellingPrice * sku.quantity) || 0).toFixed(2));
    }

    const getProductTotalPrice = (product: any) => {
        return product.skuList.reduce((p: number, sku: any) => {
            return p += getSkuTotalPrice(sku);
        }, 0).toFixed(2);
    }

    return (
        <>
            {cartSkuList.map((item: any, index: number) => {
                return (
                    <div
                        className="flex justify-between text-s p-[12px] pt-[0] mx-[12px] text-neutral-500 dark:text-neutral-400 font-normal"
                        key={index}
                    >
                        <div className="flex">
                            <div className="ml-[12px]">
                                <img src={item.pictureUrls?.[0] || noImage} className="w-[100px] h-[auto] rounded-[8px]" style={{ objectFit: "cover" }} alt="" />
                            </div>
                            <div className="flex flex-col ml-[12px]">
                                <div>{item[getKeyByLang(language, 'productName')] || item.productName || ''}</div>
                                {item.skuList.map((sku: any) => {
                                    return (
                                        <div key={sku.id}>{sku[getNameByLang(language)] || item.name || ''} x {sku.quantity}</div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex items-center">{`$${getProductTotalPrice(item)}`}</div>
                    </div>
                );
            })}
        </>
    );
}

export interface ComponentProps {
    /* 订单总价 */
    transcationTotalAmount: number;
    /* 运费 */
    shippingFee: number;
    cartSkuList: any[];
    // onCartSkuListChange?: () => void;
};

const OrderReview: FC<ComponentProps> = (props) => {
    const { t } = useTranslation();
    const { configInfo } = useContext(AuthContext);
    const { transcationTotalAmount, shippingFee, cartSkuList } = props;

    const freeShippingOrderAmount = Number(configInfo?.freeShippingOrderAmount) || 0;

    const realShippingFee = transcationTotalAmount >= freeShippingOrderAmount ? 0 : shippingFee;

    const total = (Number(transcationTotalAmount + realShippingFee) || 0).toFixed(2);

    return (
        <div className="listingSection__wrap shadow-xl">
            <div className="py-4">
                <span className="text-3xl font-semibold">{t('checkout.check_order')}</span>
            </div>
            <CheckoutSummaryRender cartSkuList={cartSkuList} />
            {/* SUM */}
            <div className="flex flex-col space-y-4" style={{ margin: "8px" }}>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                    <span>{t('checkout.subtotal')}:</span>
                    <span>{`HKD $${transcationTotalAmount}`}</span>
                </div>
                <div className="flex justify-between font-semibold">
                    <span>{t('checkout.delivery_fee')}:</span>
                    <span className={`${realShippingFee === 0 ? 'line-through' : ''}`}>{`HKD $${shippingFee}`}</span>
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                    <span>{t('cart.total_amount')}:</span>
                    <span>{`HKD $${total}`}</span>
                </div>
            </div>
        </div>
    );
};

export default OrderReview;