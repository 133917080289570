import React, { FC, useEffect, useState } from "react";
import { Affix } from 'antd';
import MainNav from "./MainNav";
import TopBanner from "components/TopBanner/index";

export interface HeaderProps { }

const Header: FC<HeaderProps> = () => {
	return (
		<div className="nc-Header w-full">
			{/* 顶部banner */}
			<TopBanner />
			<Affix offsetTop={0}>
				<MainNav isTop={false} />
			</Affix>
		</div>
	);
};

export default Header;
