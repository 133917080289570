import React, { useState, useEffect, FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { useParams } from "react-router-dom";
import { message, Result } from 'antd';
import ProductCard from "./ProductCard";
import Spinner from "components/Spinner/Spinner";
import { StayDataType } from "data/types";
import Heading2 from "components/Heading/Heading2";
import { AuthContext } from "context/authenticate";
import { commonAxiosPostRequest } from "utils/request";
import { getNameByLang } from "utils/util";

export interface SectionGridFilterCardProps {
	className?: string;
	data?: StayDataType[];
}
// 商品信息
interface TProductItem {
	id: string;
	name: string;
	nameEn: string;
	nameHk: string;
	pictureUrls: string[];
	sellingPrice: number;
	stocks: number;
};

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const { id: typeId } = useParams() as any;
	const { productTypeList } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(0); // 0初始化、1加载中、2加载完成
	const [pageNo, setPageNo] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [dataList, setDataList] = useState<TProductItem[]>([]);
	const pageSize = 40;
	const { language } = i18n;

	const handleQueryDataList = async (reset = false) => {
		if (isLoading === 1 || !hasMore) {
			return;
		}

		if (reset) {
			setPageNo(1);
			setHasMore(true);
			setDataList([]);
		}

		const currentPage = reset ? 1 : pageNo;
		setIsLoading(1);
		const [error, response] = await commonAxiosPostRequest<{ list: TProductItem[] }, {}>('/universal_mall/api/app/product/list', {
			pageNo: currentPage,
			pageSize,
			typeId,
		});
		setIsLoading(2);
		const list = response?.list;
		if (Array.isArray(list)) {
			setDataList(prevDataList => (reset ? list : [...prevDataList, ...list]));
			setPageNo(currentPage + 1);
			setHasMore(list.length < pageSize);
		} else {
			message.error(error?.message || 'The system is busy');
		}
	}

	useEffect(() => {
		handleQueryDataList(true);
	}, [typeId]);

	const title = productTypeList.find(n => n.id == typeId)?.[getNameByLang(language)] || t('product.title');

	if (isLoading === 1) {
		return <Spinner className="items-center pt-24 pb-24 lg:py-32" />;
	}

	if (!dataList.length && isLoading === 2) {
		return <Result
			status="404"
			title={t('product.empty_text')}
			subTitle={t('product.empty_desc')}
		/>
	}

	return (
		<div
			className={`w-full nc-SectionGridFilterCard mt-[64px] mt-8 sm:mt-8 lg:mt-12 ${className}`}
			data-nc-id="SectionGridFilterCard"
		>
			<Heading2 heading={title} className="mb-8 mb-0" />
			<div className="p-6 md:p-8 lg:p-10 xl:p-12">
				<div className="grid grid-cols-1 gap-4 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{dataList.map((item) => (
						<ProductCard key={item.id} uniqueKey={item.id} data={item} />
					))}
				</div>
			</div>

		</div>
	);
};

export default SectionGridFilterCard;