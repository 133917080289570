import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { commonAxiosPostRequest, commonAxiosGetRequest } from "utils/request";
import { getPureArray } from "utils/util";

interface IConfigInfo {
	/** 用户协议 */
	userAgreementContent: string;
	/** 隐私协议 */
	privacyPolicyContent: string;
	/** 关于我们 */
	aboutUsContent: string;
	/** 免运费订单金额 */
	freeShippingOrderAmount: number;
};

interface AnyInterface {
	auth: boolean;
	setAuth: (boolean: boolean) => void;
	showNavItems: boolean;
	setShowNavItems: (boolean: boolean) => void;
	configInfo: IConfigInfo | null;
	setConfigInfo: (data: IConfigInfo) => void;
	productTypeList: IProductTypeInfo[];
}

interface IProductTypeInfo {
	id: string;
	name: string;
	nameHk: string;
	nameEn: string;
	pictureUrl: string;
	linkUrl: string;
};

const defaultState = {
	auth: false,
	setAuth: () => { },
	email: "",
	showNavItems: false,
	setShowNavItems: () => { },
	configInfo: null,
	setConfigInfo: () => { },
	productTypeList: [],
};

const AuthContext = React.createContext<AnyInterface>(defaultState);

const AuthProvider: React.FC = ({ children }) => {
	const { t } = useTranslation();
	const [auth, setAuth] = useState<boolean>(false);
	const [showNavItems, setShowNavItems] = useState<boolean>(false);
	const [configInfo, setConfigInfo] = useState<IConfigInfo | null>(null);
	const [productTypeList, setProductTypeList] = useState<IProductTypeInfo[]>([]);

	const handleQueryConfigInfo = async () => {
		const [error, response] = await commonAxiosPostRequest<{ config: IConfigInfo }, {}>('/universal_mall/api/app/common/config', {});
		const config = response?.config || null;
		setConfigInfo(config);
	}

	const handleQueryDataList = async () => {
		const [error, response] = await commonAxiosPostRequest<{ list: IProductTypeInfo[] }, {}>('/universal_mall/api/app/product/typeList', {
			scene: 1
		});
		const list = getPureArray<IProductTypeInfo>(response?.list);
		setProductTypeList(list);
	}

	useEffect(() => {
		handleQueryConfigInfo();
		handleQueryDataList();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				showNavItems,
				setShowNavItems,
				configInfo,
				setConfigInfo,
				productTypeList,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
