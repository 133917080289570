import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
// import AccountPage from "containers/AccountPage/AccountPage";
// import AccountPass from "containers/AccountPage/AccountPass";
// import AccountSavelists from "containers/AccountPage/AccountSavelists";
// import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageServices from "containers/PageServices/index";
// import PageSignUp from "containers/PageSignUp/PageSignUp";
// import PageLogin from "containers/PageLogin/PageLogin";
// import PageSubcription from "containers/PageSubcription/PageSubcription";

import ProductsPage from "containers/ProductPage/ProductPage";
import ProductCategoryPage from "containers/ProductPage/ProductCategoryPage";
import ProductDetailPage from "containers/ProductDetailPage/index";
import Cart from "containers/CartPage/CartPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PaymentConfirmPage from "containers/PaymentConfirmPage/PaymentConfirmPage";

import Carousel from "components/Carousel/index";

export const pages: Page[] = [
	{ path: "/", exact: true, component: ProductsPage },
	{ path: "/category/:id", exact: true, component: ProductCategoryPage },
	{ path: "/product-detail/:id", component: ProductDetailPage },
	{ path: "/cart", component: Cart },
	{ path: "/checkout", component: CheckOutPage },
	{ path: "/payment-result", component: PaymentConfirmPage },
	// { path: "/account", component: AccountPage },
	// { path: "/account-password", component: AccountPass },
	// { path: "/account-savelists", component: AccountSavelists },
	// { path: "/account-billing", component: AccountBilling },
	{ path: "/contact", component: PageContact },
	{ path: "/about", component: PageAbout },
	{ path: "/services", component: PageServices },
	// { path: "/signup", component: PageSignUp },
	// { path: "/login", component: PageLogin },
	// { path: "/subscription", component: PageSubcription },
];

const Routes = () => {
	return (
		<BrowserRouter>
			<Header />
			<Carousel />
			<Switch>
				{pages.map(({ component, path, exact }) => {
					return (
						<Route
							key={path}
							component={component as any}
							exact={!!exact}
							path={path}
						/>
					);
				})}
				<Route component={Page404} />
			</Switch>
			<Footer />
		</BrowserRouter>
	);
};

export default Routes;
