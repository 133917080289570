import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { useParams, useHistory } from "react-router-dom";
import { getPureArray, safeParse, getNameByLang, getKeyByLang } from "utils/util";
import { IProductInfo, ISku, TSkuTypes, TSkuAttribute } from "../index";
import { CartCacheLocalStorageKey } from "utils/constant";
import CommonDialog from "../../../components/Dialog/index";
import "./Sidebar.css";

export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
    onChange?: (skuInfo: ISku | null) => void;
};

const SidebarRender: FC<ComponentProps> = ({ className = "", productInfo, onChange }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const currentSkuList = useRef<TSkuTypes[][]>([]);
    const valueIdSortAndIndex = useRef<({ valueIdSort: string[] })[]>([]);
    const selectItemList = useRef<({ grounpId: string, id: string, index: number, i: number })[]>([]);
    // 属性规则列表
    const [goodsTypeList, setGoodsTypeList] = useState<TSkuAttribute[]>([]);
    // 当前选择的SKU信息
    const [currentSkuInfo, setCurrentSkuInfo] = useState<ISku | null>(null);
    // 弹框展示信息
    const [dialogInfo, setDialogInfo] = useState({
        show: false,
        title: '',
        content: '',
    });
    const [isInit, setIsInit] = useState<boolean>(true);
    const { language } = i18n;

    const getAttrNameByLang = (inputName: string) => {
        const [name, nameEn, nameHk] = inputName.split('_______');
        return (language === 'zh-HK' ? nameHk : language === 'zh-CN' ? name : nameEn) || inputName;
    }

    // 添加商品至购物车
    const onAddCartingButtonClick = () => {
        const buttonDisabled = selectItemList.current.length !== goodsTypeList.length || (!currentSkuInfo?.stocks);
        if (buttonDisabled) {
            setDialogInfo((prev) => ({ ...prev, show: true, title: t('common.tips'), content: t('product.choose_sku_tips') }));
            return;
        }
        console.log('[当前选择的商品]', currentSkuInfo);
        // 获取缓存中的购物车数据
        let cachedCartSkuList: any[] = safeParse(window.localStorage.getItem(CartCacheLocalStorageKey), []);
        const index = cachedCartSkuList.findIndex(item => item.productId === currentSkuInfo.productId);
        if (index === -1) {
            cachedCartSkuList.push({ productId: currentSkuInfo.productId, skuList: [currentSkuInfo] });
        } else {
            let skuList: ISku[] = cachedCartSkuList[index].skuList;
            const skuIndex = skuList.findIndex(item => item.id === currentSkuInfo.id);
            if (skuIndex !== -1) {
                // @ts-ignore
                skuList[skuIndex].quantity += (currentSkuInfo.quantity || 0);
            } else {
                skuList.push(currentSkuInfo);
            }
            cachedCartSkuList[index].skuList = skuList;
        }
        window.localStorage.setItem(CartCacheLocalStorageKey, JSON.stringify(cachedCartSkuList));
        history.push("/cart");
    };

    // 初始化
    const init = () => {
        let __valueIdSortAndIndex: ({ valueIdSort: string[] })[] = [];
        let __currentSkuList = getPureArray<ISku>(productInfo?.skus).map((item, index) => {
            let valueIdSort: string[] = [];
            getPureArray<TSkuTypes>(item?.skusTypes).forEach(skusTypesItem => {
                valueIdSort.push(skusTypesItem.propertyId);
            });
            /*
             将sku规格值的ID，即 propertyId，组成一个数组并且字典排序，以及将该sku所对应的index存入数组，供点击后查询sku*/
            __valueIdSortAndIndex.push({ valueIdSort: valueIdSort.sort() })
            return item.skusTypes;
        });
        currentSkuList.current = __currentSkuList;
        valueIdSortAndIndex.current = __valueIdSortAndIndex;
    }

    // 获取所有的规格
    const getShopGoodsType = () => {
        const __goodsTypeList = getPureArray<TSkuAttribute>(productInfo?.skuAttributeList).map(item => ({
            ...item,
            propertyList: item.propertyList.map(n => ({ ...n, selected: false, disabled: true }))
        }));
        const newGoodsTypeList = initGoodType(__goodsTypeList);
        setGoodsTypeList(newGoodsTypeList);
    }

    // 初始化 判断 是否有库存
    const initGoodType = (typeList: TSkuAttribute[]) => {
        let __currentSkuList: TSkuTypes[] = currentSkuList.current.flat();
        const __goodsTypeList = [...typeList].map(items => {
            return {
                ...items,
                propertyList: items.propertyList.map(item => {
                    const disabled = !!__currentSkuList.find(n => n.propertyId == item.id);
                    return { ...item, disabled: !disabled };
                })
            };
        });
        return __goodsTypeList;
    }

    // 设置默认选择一个sku
    const setDefaultSelect = () => {
        const skus = productInfo?.skus;
        if (!Array.isArray(skus) || !skus.length) {
            return;
        }
        const { skusTypes } = skus[0];
        goodsTypeList.forEach((item, index) => {
            const { propertyList } = item;
            const curSkusType = skusTypes.find(n => n.grounpId === item.id);
            if (curSkusType) {
                const elIndex = propertyList.findIndex(n => n.id === curSkusType.propertyId);
                if (elIndex !== -1) {
                    const el = propertyList[elIndex];
                    handleClickSpecs1(item.id, el.id, el, index, elIndex);
                }
            }
        });
    }

    // 选择每个规格，判断对应的库存
    const handleClickSpecs1 = (grounpId: string, id: string, el: any, index: number, i: number) => {
        // 已选规格
        let __selectItemList = selectItemList.current;
        let __goodsTypeList = [...goodsTypeList];

        if (el.disabled) {
            __goodsTypeList = goodsTypeList.map((item, index1) => ({
                ...item,
                propertyList: item.propertyList.map((info, index2) => {
                    return {
                        ...info,
                        selected: false,
                        disabled: false,
                    };
                })
            }));
        }
        // 处理selected的逻辑
        if (!el.selected) {
            const propertyList = __goodsTypeList[index].propertyList;
            __goodsTypeList[index].propertyList = propertyList.map((item, ii) => ({
                ...item,
                selected: ii === i ? true : false,
            }));
            // 选中项中有同组元素，替换
            __selectItemList.forEach((item, x_selected_index) => {
                if (item.index === index) {
                    __selectItemList.splice(x_selected_index, 1);
                }
            });
            __selectItemList.push({ grounpId, id, index, i });
        } else {
            // 取消选中并删除选中项中数据
            __goodsTypeList[index].propertyList[i].selected = false;
            __selectItemList.forEach((item, x_selected_index) => {
                if (item.id === id && item.grounpId === grounpId) {
                    __selectItemList.splice(x_selected_index, 1);
                }
            });
        }

        selectItemList.current = __selectItemList;
        // 取出组ID
        let x_selected_grounpIds = __selectItemList.map(n => n.grounpId);

        // 处理disabled的逻辑
        if (__selectItemList.length == 0) {
            // 选中属性为空，重新初始化数据
            __goodsTypeList = initGoodType(__goodsTypeList);
        } else {
            //拿所有规格属性（即goodsTypeList）的每一项分别与已选中的数据（即selectItemList）的每一项，组成一个比较项，与现有库存比较，找到存在的可点项
            __goodsTypeList = __goodsTypeList.map((goodsType, goodsTypeIndex) => {
                const propertyList = goodsType.propertyList.map((prop, propIndex) => {
                    if (!prop.selected) {
                        prop.disabled = true;
                    }
                    // 本次循环数据
                    let push_data = { grounpId: goodsType.id, id: prop.id, index: goodsTypeIndex, i: propIndex }
                    if (x_selected_grounpIds.indexOf(goodsType.id) > -1) {
                        // 当前循环的规格的组ID在已选规格中，删除同组规格，用当前规格替换后去与库存比较
                        let sel = __selectItemList.slice(); // 用一个新变量接受数据，防止修改源数据
                        let index_splice = x_selected_grounpIds.indexOf(goodsType.id);
                        sel.splice(index_splice, 1, push_data);
                        // 将当前比较项的属性ID提取
                        let propertyIds = sel.map(n => n.id);
                        // 在现有库存中查找是否有可选项，可选的置为可点击
                        valueIdSortAndIndex.current.map(item => {
                            if (isContained(item.valueIdSort, propertyIds)) {
                                prop.disabled = false;
                            }
                        });
                    } else {
                        //  当前循环规格组ID不在已选规格中，添加当前规格到复制出来的已选数组中，循环比较
                        let sel = __selectItemList.slice();
                        sel.push(push_data);
                        let propertyIds = sel.map(n => n.id);
                        // 在现有库存中查找是否有可选项，可选的置为可点击
                        valueIdSortAndIndex.current.map(item => {
                            if (isContained(item.valueIdSort, propertyIds)) {
                                prop.disabled = false;
                            }
                        });
                    }
                    return { ...prop };
                });
                return {
                    ...goodsType,
                    propertyList,
                };
            });
        }
        setGoodsTypeList(__goodsTypeList);
        handleGetCurrentSkuInfo(__goodsTypeList);
    }

    function handleGetCurrentSkuInfo(_goodsTypeList: TSkuAttribute[]) {
        const val = selectItemList.current;
        if (val.length === _goodsTypeList.length) {
            const attribute = val.reduce((p: any, v) => {
                const info = getPureArray<TSkuAttribute>(productInfo?.skuAttributeList).find(n => n.id === v.grounpId);
                const value = (info?.propertyList || []).find(n => n.id === v.id);
                if (info) {
                    p[info.name] = value?.name || '';
                }
                return p;
            }, {});

            const currentSkuInfo = getPureArray<ISku>(productInfo?.skus).find(item => {
                return Object.keys(item.attribute).every(n => item.attribute[n] === attribute[n]);
            });
            if (currentSkuInfo) {
                currentSkuInfo.attributeText = Object.values(attribute).map(item => getAttrNameByLang(item as string)).join('/');
                currentSkuInfo.quantity = 1;
                currentSkuInfo.totalPrice = Number(currentSkuInfo?.sellingPrice);
                setCurrentSkuInfo({ ...currentSkuInfo });
            } else {
                setCurrentSkuInfo(null);
            }
        } else {
            setCurrentSkuInfo(null);
        }
    }

    function isContained(aa: any[], bb: any[]) {
        //判断aa数组是否 全 包含bb数组
        if (!(aa instanceof Array)
            || !(bb instanceof Array)
            || (aa.length < bb.length)
        ) {
            return false;
        }
        let aaStr = aa.toString();
        for (var i = 0; i < bb.length; i++) {
            if (aaStr.indexOf(bb[i]) < 0) {
                return false;
            }
        }
        return true;
    }

    const onQuantityChange = (type: 'add' | 'reduce') => {
        if (selectItemList.current.length !== goodsTypeList.length || !currentSkuInfo) {
            setDialogInfo((prev) => ({ ...prev, show: true, title: t('product.complete_sku_tips') }))
            return;
        }

        const { stocks, quantity = 0, sellingPrice } = currentSkuInfo;
        let newQuantity = quantity;
        if (type === 'add') {
            if (quantity >= stocks) {
                setDialogInfo((prev) => ({ ...prev, show: true, title: t('product.inventory_shortage_tips') }))
            } else {
                newQuantity += 1;
            }
        } else {
            if (quantity > 1) {
                newQuantity -= 1;
            }
        }
        // 计算总价
        const totalPrice = (Number(sellingPrice) * newQuantity).toFixed(2);
        setCurrentSkuInfo({ ...currentSkuInfo, totalPrice, quantity: newQuantity });
    }


    useEffect(() => {
        init();
        getShopGoodsType();
    }, []);

    useEffect(() => {
        if (isInit && goodsTypeList.length > 0 && goodsTypeList.every(item => item.propertyList.every(e => !e.selected))) {
            setDefaultSelect();
            setIsInit(false);
        }
    }, [goodsTypeList]);

    useEffect(() => {
        onChange && onChange(currentSkuInfo);
    }, [currentSkuInfo]);

    return (
        <div className="listingSection__wrap lg:shadow-xl">
            {
                goodsTypeList.map((item, index) => (
                    <div className="mt-[2px]" key={index}>
                        <div className="text-[16px] text-[#000]">{item[getNameByLang(language)] || item.name}</div>
                        <div className="flex items-center flex-wrap mt-[8px]">
                            {
                                item.propertyList.map((el, i) => (
                                    <div
                                        className={`attribute-value cursor-pointer flex items-center justify-center bg-[#F7F7F7] rounded-[10px] text-[14px] text-[#000001] px-[12px] mr-[12px] ${el.selected ? 'active' : ''} ${el.disabled ? 'disabled' : ''}`}
                                        key={i}
                                        onClick={() => handleClickSpecs1(item.id, el.id, el, index, i)}>
                                        {getAttrNameByLang(el.name)}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
            {
                currentSkuInfo ? (
                    <div className="flex">
                        <img src={currentSkuInfo?.pictureUrl || ''} alt={currentSkuInfo?.name || ''} className="w-[80px] h-[80px] shrink-0 mr-[12px]" />
                        <div className="">
                            <div className="text-[16px]"> {currentSkuInfo?.[getNameByLang(language)] || currentSkuInfo?.name || ''}</div>
                            <div className="text-[16px] font-semibold mt-[4px]"> {`HKD $${currentSkuInfo?.sellingPrice || ''}`}</div>
                            <div className="text-[14px] text-[#333]">{t('product.choosed')}： {currentSkuInfo?.attributeText || ''}</div>
                        </div>
                    </div>
                ) : null
            }
            <div className="flex flex-col space-y-4">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{t('product.count')}：</span>
                    <div className="flex items-center">
                        <MinusCircleIcon className={`cursor-pointer w-7 h-7 text-[#ff043a] ${(currentSkuInfo?.quantity || 0) <= 1 ? 'opacity-40' : ''}`} onClick={() => onQuantityChange('reduce')} />
                        <div className="px-[8px]">{currentSkuInfo?.quantity || 0}</div>
                        <PlusCircleIcon className={`cursor-pointer w-7 h-7 text-[#ff043a] ${(currentSkuInfo?.quantity || 0) > (currentSkuInfo?.stocks || 0) ? 'opacity-40' : ''}`} onClick={() => onQuantityChange('add')} />
                    </div>
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                    <span>{t('cart.total_amount')}：</span>
                    <span>{`HKD $${currentSkuInfo?.totalPrice || 0}`}</span>
                </div>
            </div>
            <ButtonPrimary onClick={onAddCartingButtonClick}>{t('cart.add_to_cart')}</ButtonPrimary>
            <CommonDialog {...dialogInfo} onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))} />
        </div>
    );
};

export default SidebarRender;