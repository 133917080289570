import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Link } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import companyLogo from "images/logo.png";
import SocialsList from "shared/SocialsList/SocialsList";
import { AuthContext } from "context/authenticate";
import { Menu } from 'antd';

export interface NavMobileProps {
	navigationList: any[];
	currentKey: string;
	data?: NavItemType[];
	onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = (props) => {
	const { t } = useTranslation();
	const { navigationList, currentKey, onClickClose } = props;
	const { showNavItems } = useContext(AuthContext);

	return (
		<div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
			<div className="py-6 px-5">
				<Logo img={companyLogo} homePageUrl={'/'} className="flex justify-center" />
				<div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
					<span></span>
					<div className="flex justify-center items-center mt-4">
						<SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
					</div>
				</div>
				<span className="absolute right-2 top-2 p-1">
					<ButtonClose onClick={onClickClose} />
				</span>
			</div>
			<div className="flex flex-col py-6 px-2 space-y-1">
				<Menu mode="inline" selectedKeys={[currentKey]} className="w-full">
					{
						navigationList.map(item => (
							<Menu.Item key={item.key} className="text-[18px]">
								<Link to={item.link}>{item.name}</Link>
							</Menu.Item>
						))
					}
				</Menu>
			</div>
			<div className="flex items-center justify-center py-6 px-5 space-x-4">
				<ButtonPrimary href="/cart" onClick={onClickClose}>
					{t('cart.link_to_cart')}
				</ButtonPrimary>
			</div>
		</div>
	);
};

export default NavMobile;
