import React, { FC, SelectHTMLAttributes } from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	className?: string;
	sizeClass?: string;
	placeholder?: string;
}

const Select: FC<SelectProps> = ({
	className = "",
	sizeClass = "h-11",
	placeholder,
	children,
	...args
}) => {
	return (
		<select
			className={`nc-Select ${sizeClass} ${className} block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
			placeholder={placeholder || ''}
			{...args}
		>
			{children}
		</select>
	);
};

export default Select;
