import React, { useEffect, useState } from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
	index?: string;
	nextHref?: string;
	backHref?: string;
	nextBtnText?: string;
	cart?: any;
	onClick?: () => void;
	enableNext?: boolean;
	reason?: string;
	inputCode?: string;
}

const CommonLayout: FC<CommonLayoutProps> = ({
	index = "01",
	children,
	nextHref,
	nextBtnText,
	backHref,
	cart,
	onClick,
	enableNext,
	reason,
	inputCode,
}) => {
	const [cartExist, setCartExist] = useState(false);

	useEffect(() => {
		if (cart?.length > 0) {
			setCartExist(true);
		} else {
			setCartExist(false);
		}
	}, [cart]);

	return (
		<div
			className={`nc-PageAddListing px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
			data-nc-id="PageAddListing"
		>
			<div className="space-y-11">
				<div className="listingSection__wrap ">{children}</div>
				<div className="flex justify-end space-x-5">
					<ButtonSecondary href={backHref}>{"返回"}</ButtonSecondary>
					{cartExist && (
						<>
							{reason !== "" && (
								<>
									{inputCode !== "" && (
										<ButtonPrimary href={nextHref} onClick={onClick} disabled>
											{nextBtnText || "結帳"}
										</ButtonPrimary>
									)}

									{inputCode === "" && (
										<ButtonPrimary href={nextHref} onClick={onClick} disabled={!enableNext ? true : false}>
											{nextBtnText || "結帳"}
										</ButtonPrimary>
									)}
								</>
							)}

							{reason === "" && (
								<ButtonPrimary href={nextHref} onClick={onClick} disabled={!enableNext ? true : false}>
									{nextBtnText || "結帳"}
								</ButtonPrimary>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommonLayout;
