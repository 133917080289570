import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
	index?: string;
	nextHref?: string;
	backtHref?: string;
	nextBtnText?: string;
	onClick?: () => void;
}

const OneButtonCommonLayout: FC<CommonLayoutProps> = ({
	index = "01",
	children,
	nextHref,
	nextBtnText,
	backtHref,
	onClick,
}) => {

	return (
		<div className={`px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24`}>
			<div className="space-y-11">
				<div className="listingSection__wrap">{children}</div>
				<div className="flex justify-end space-x-5">
					<ButtonPrimary href={backtHref} onClick={onClick}>
						{"返回首頁"}
					</ButtonPrimary>
				</div>
			</div>
		</div>
	);
};

export default OneButtonCommonLayout;
