import React, { FC, Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { getPureArray } from "utils/util";


export interface ComponentProps {
    className?: string;
    show: boolean;
    title?: string;
    content?: string;
    onClose: Function;
};

const CommonDialog: FC<ComponentProps> = (props) => {
    const { className, show, title, content } = props;

    const onClose = () => {
        props?.onClose?.();
    }

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                    </Transition.Child>
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block py-8 h-screen w-full">
                            <div
                                className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                                style={{ height: "25%" }}
                            >
                                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900" id="headlessui-dialog-title-70">
                                        {title || ''}
                                    </h3>
                                    <ButtonClose className="absolute left-3 top-3" onClick={onClose} />
                                </div>
                                <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                    <div className="flex items-center space-x-8 pt-[24px] text-[20px]">
                                        {content || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default CommonDialog;