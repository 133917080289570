import React, { useContext, useEffect } from "react";
import MyRouter from "routers/index";
import "./css/antd.css";

function App() {
	return (
		<div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
			<MyRouter />
		</div>
	);
}

export default App;
