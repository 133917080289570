import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { commonAxiosPostRequest, commonAxiosGetRequest } from "utils/request";
import { getPureArray, getKeyByLang } from "utils/util";

export interface PageAboutProps {
	className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const { language } = i18n;
	const [configInfo, setConfigInfo] = useState<any>({});

	const handleQueryConfigInfo = async () => {
		const [error, response] = await commonAxiosPostRequest<{ config: any }, {}>('/universal_mall/api/app/common/config', {});
		const config = response?.config || {};
		setConfigInfo(config);
	}

	useEffect(() => {
		handleQueryConfigInfo();
	}, []);

	const aboutUsContent = configInfo?.[getKeyByLang(language, 'aboutUsContent')] || configInfo?.aboutUsContent || '';

	return (
		<div
			className={`nc-PageAbout overflow-hidden relative ${className}`}
			data-nc-id="PageAbout"
		>
			<BgGlassmorphism />
			<div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
				<div dangerouslySetInnerHTML={{ __html: aboutUsContent || '' }}></div>
			</div>
		</div>
	);
};

export default PageAbout;
