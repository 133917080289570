/**
 * 强制获取数组类型的数据,当输入参数为非数组时，返回空数组
 * @param {AnyObject} params - 跳转链接
 * @returns {Promise<any[]>} 数组
 */
export function getPureArray<T>(params: any): T[] {
    return Array.isArray(params) ? params : [];
}

// 安全执行JSON.parse
export function safeParse(str: any, defaultValue = {}) {
    if (!str || typeof str !== 'string') {
        return str || defaultValue;
    }
    let result = defaultValue;
    try {
        result = JSON.parse(str);
    } catch (error) {
    }
    return result;
};

// 判断是否为移动端浏览器
export function checkBrowser() {
    if (!global.navigator || !global.navigator.userAgent) {
        return { isIos: false, isMobile: false };
    }
    const isIos = /AppleWebKit/.test(navigator.userAgent) && /Mobile\/\w+/.test(navigator.userAgent);
    const isMobile = isIos || /Android|webOS|BlackBerry|Opera Mini|Opera Mobi|IEMobile/i.test(navigator.userAgent);
    return {
        isIos,
        isMobile
    };
}

// 判断是否为合法手机号码的方法，支持香港地区手机号和大陆地区手机号
export function isValidPhoneNumber(phoneNumber: string) {
    // 大陆地区手机号码正则表达式
    const mainlandChinaRegex = /^1[3-9]\d{9}$/;
    // 香港地区手机号码正则表达式
    const hongKongRegex = /^([1-9])\d{7}$/;
    // 测试手机号码是否符合大陆地区或香港地区的规则
    return mainlandChinaRegex.test(phoneNumber) || hongKongRegex.test(phoneNumber);
}

export const getNameByLang = (language: string) => {
    return language === 'zh-HK' ? 'nameHk' : language === 'zh-CN' ? 'name' : 'nameEn';
}

export const getKeyByLang = (language: string, key: string) => {
    return language === 'zh-HK' ? `${key}Hk` : language === 'zh-CN' ? key : `${key}En`;
}

export const getCurrentPage = () => {
    const [, page, id] = window.location.pathname.split('/');
    return { page: page || 'index', id };
}