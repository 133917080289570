import React from "react";
import ReactDOM from "react-dom";
import "./react-i18next/i18n";

// STYLE
import "./styles/index.scss";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CartProvider } from "./context/cart";
import { AuthProvider } from "context/authenticate";

ReactDOM.render(
	<React.Suspense fallback={'loading'}>
		<AuthProvider>
			<CartProvider>
				<App />
			</CartProvider>
		</AuthProvider>
	</React.Suspense>
	, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
