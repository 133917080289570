import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import GallerySlider from "components/GallerySlider/GallerySlider";
import noImage from "../../images/404.png";
import { getPureArray, getNameByLang } from "utils/util";

// 商品信息
interface TProductItem {
	id: string;
	name: string;
	nameHk: string;
	nameEn: string;
	pictureUrls: string[];
	sellingPrice: number;
	stocks: number;
};


export interface ProductCardProps {
	className?: string;
	ratioClass?: string;
	uniqueKey: string;
	data: TProductItem;
	size?: "default" | "small";
}

const ProductCard: FC<ProductCardProps> = ({
	size = "default",
	className = "",
	data,
	ratioClass,
	uniqueKey = "",
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { language } = i18n;
	// @ts-ignore
	const { name, id, pictureUrls, sellingPrice, skuList } = data;

	const soldOut = false;
	const discountFlag = true;

	const pictureUrlList = getPureArray(pictureUrls).length > 0 ? pictureUrls : [noImage];

	const onItemClick = () => {
		history.push(`/product-detail/${id}`);
	}

	const renderContent = () => {
		return (
			<div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
				<div className="space-y-2">
					<div className="flex items-center space-x-2">
						<h2 className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
							<span className="line-clamp-3 text-[18px]">{data[getNameByLang(language)] || name}</span>
						</h2>
					</div>
					<div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
					</div>
				</div>
				<div className="w-100 border-b border-neutral-100 dark:border-neutral-800"></div>
				<div className="flex justify-between items-center">
					{soldOut ? (
						<span className="text-base font-semibold text-white bg-[red] rounded-[8px] px-[3px] py-[3px]">{t('product.soldout_tag')}</span>
					) : (
						<>
							{discountFlag ? (
								<span className="text-base font-semibold text-white bg-[orange] rounded-[8px] px-[3px] py-[3px]">{t('product.discount_tag')}</span>
							) : (
								<div></div>
							)}
						</>
					)}
					<div>
						{
							getPureArray(skuList).map((sku: any) => {
								return <p className="text-base font-semibold mb-[0]">{sku[getNameByLang(language)]} $HKD{sku.sellingPrice}</p>
							})
						}
					</div>
					{/* <span className="text-base font-semibold">
						{`HKD $${sellingPrice}`}
						{` `}
						{size === "default" && (
							<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">{"per unit"}</span>
						)}
					</span> */}

				</div>
			</div>
		);
	};

	useEffect(() => {
	}, []);

	return (
		<div
			className={`nc-ProductCard cursor-pointer group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
			data-nc-id={uniqueKey}
			onClick={onItemClick}
		>
			<div className="">
				<div className="relative w-full">
					<GallerySlider ratioClass={ratioClass} galleryImgs={pictureUrlList} />
				</div>
				{renderContent()}
			</div>
		</div>
	);
};

export default ProductCard;
