import React from "react";
import { ReactNode } from "react";

export interface Heading2Props {
	heading?: ReactNode;
	subHeading?: ReactNode;
	className?: string;
	id?: string;
}

const Heading2: React.FC<Heading2Props> = ({
	className = "",
	id = "",
	heading = "",
	subHeading,
}) => {
	return (
		<div id={id || ''} className={`mb-12 lg:mb-8 ${className} flex flex-col items-center`}>
			<h2 className="text-4xl font-semibold mb-0">{heading}</h2>
			{subHeading ? subHeading : null}
		</div>
	);
};

export default Heading2;
