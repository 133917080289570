import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from './locales/resources';

const language = localStorage.getItem('language') || 'en';

// 所有配置选项: https://www.i18next.com/overview/configuration-options
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        lng: language,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            caches: ['localStorage', 'sessionStorage', 'cookie'],
        }
    });

export default i18n;
