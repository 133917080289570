import React from "react";
import { Link } from "react-router-dom";
// import logoImg from "images/logo.png";

export interface LogoProps {
	img?: string;
	imgLight?: string;
	className?: string;
	homePageUrl?: string;
}

const Logo: React.FC<LogoProps> = ({ img = "", imgLight = null, className = "", homePageUrl = "" }) => {
	return (
		<>
			<a
				href={homePageUrl}
				className={`flex items-center h-[80px] ttnc-logo inline-block text-primary-6000 ${className}`}
				style={{ flexShrink: 0 }}
			>
				{img ? (
					<img
						className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
						style={{ width: "85px", maxHeight: "100%", objectFit: "cover" }}
						src={img}
						alt="Logo"
					/>
				) : (
					""
				)}
				{imgLight && <img className="hidden max-h-12 dark:block" src={imgLight} alt="Logo-Light" />}
			</a>
		</>
	);
};

export default Logo;
