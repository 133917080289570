export const imageBucketUrl = 'https://taotaro-product.s3-us-west-2.amazonaws.com/';
export const prodBucketName = 'taotaro-product';
export const getSecretUrl = 'https://testapi.taotaroapp.com/api/objects/S3link';
export const emailRegex = /\S+@\S+\.\S+/;
export const merchantToken = "63ebc7d0-f3be-4dde-b4db-f8ca37b304dc";

// 支付方式
export const PaymentOptions = [
    // { value: "轉數快" },
    // { label: "PayMe", value: "PayMe" },
    { label: "信用卡", value: "CreditCard" },
    { label: "Wechat", value: "Wechat" },
    { label: "Alipay", value: "Alipay" },
    { label: "Fps", value: "Fps" },
];


export const BuyChInfoList = [
    { label: "請選擇", value: "" },
    { label: "Facebook / Instagram", value: "Facebook / Instagram" },
    { label: "展覽會", value: "展覽會" },
    { label: "舊有客戶", value: "舊有客戶" },
    { label: "HKTVMALL", value: "HKTVMALL" },
    { label: "網上搜尋", value: "網上搜尋" },
    { label: "朋友介紹", value: "朋友介紹" },
    { label: "其他〈請註明〉", value: "其他〈請註明〉" },
];
