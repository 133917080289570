import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { getNameByLang } from "utils/util";
import { IProductInfo } from "../index";


const getDetailContentByLang = (language: string) => {
    return language === 'zh-HK' ? 'detailContentHk' : language === 'zh-CN' ? 'detailContent' : 'detailContentEn';
}

export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
};

const IntroDetailRender: FC<ComponentProps> = ({ className = "", productInfo }) => {
    const { t } = useTranslation();
    const { language } = i18n;

    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">{productInfo?.[getNameByLang(language)] || productInfo?.name || ''}</h2>
            <div className="w-100 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300" dangerouslySetInnerHTML={{ __html: productInfo?.[getDetailContentByLang(language)] || productInfo?.detailContent || '' }}></div>
        </div>
    );
};

export default IntroDetailRender;