import React, { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import i18n from 'i18next';
import languageIcon from "images/icon/lang.svg";
import { Menu, Dropdown } from 'antd';
import type { MenuProps } from 'antd';

export interface ComponentProps { }

const ChangeLang: FC<ComponentProps> = () => {
	const items: MenuProps['items'] = [
		{ key: 'zh-HK', label: '繁體中文' },
		{ key: 'zh-CN', label: '简体中文' },
		{ key: 'en', label: 'English' }
	];

	const onChangeLanguage = (e: any) => {
		localStorage.setItem('language', e.key);
		i18n.changeLanguage(e.key).then(() => {
			window.location.reload();
		});
	}

	useEffect(() => {

	}, []);

	return (
		<Dropdown menu={{ items, onClick: onChangeLanguage }} placement="bottom">
			<NcImage className="w-[28px] h-[auto]" src={languageIcon} />
		</Dropdown>
	);
};

export default ChangeLang;
