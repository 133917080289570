import React, { FC, Fragment, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Button, Drawer, Radio, Space } from "antd";
import SidebarRender from "../components/Sidebar";
import { getPureArray } from "utils/util";
import { IProductInfo } from "../index";


export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
};

const PolicyRender: FC<ComponentProps> = ({ className = "", productInfo }) => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [currentSkuInfo, setCurrentSkuInfo] = useState<any>(null);

    const onAddCartingButtonClick = () => {
        setDialogVisible(true);
    };

    const onClose = () => {
        setDialogVisible(false);
    }

    const onCurrentSkuChange = (e: any) => {
        console.log('onCurrentSkuChange', e);
    }

    if (!productInfo) {
        return null;
    }

    return (
        <>
            <Drawer
                title={t('common.select_placeholder')}
                placement={'left'}
                onClose={onClose}
                open={dialogVisible}
                height={'fit-content'}
                bodyStyle={{ padding: '12px' }}
            >
                <SidebarRender productInfo={productInfo} onChange={onCurrentSkuChange}></SidebarRender>
            </Drawer>
            <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
                <div className="container flex items-center justify-between px-[12px]">
                    <span className="text-2xl font-semibold">
                        <span>{`HKD $${currentSkuInfo?.totalPrice || 0}`}</span>
                        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">{t('cart.price')}</span>
                    </span>
                    <ButtonPrimary onClick={onAddCartingButtonClick}>{t('cart.add_to_cart')}</ButtonPrimary>
                </div>
            </div>
        </>

    );
};

export default PolicyRender;