import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getPureArray } from "utils/util";
import { IProductInfo } from "../index";


export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
};

const PromoDetailRender: FC<ComponentProps> = ({ className = "", productInfo }) => {
    const { t } = useTranslation();
    const promotionDescriptionList: any[] = [];

    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">{t('product.promo_title')}</h2>
            <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
                {promotionDescriptionList.map((bullets: any, index: number) => {
                    return (
                        <React.Fragment key={`promo-${bullets}-index-${index}`}>
                            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">{bullets}</span>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default PromoDetailRender;