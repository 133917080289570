import React, { FC, Fragment } from "react";
import CustomerInfoPage from "containers/CustomerInfoPage/CustomerInfoPage";


export interface CheckOutPageProps {
	className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
	return (
		<>
			<CustomerInfoPage />
		</>
	);
};

export default CheckOutPage;
