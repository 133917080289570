import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import Spinner from "components/Spinner/Spinner";
import Banner from "components/Banner/Banner";
import PromoDetailRender from "./components/PromoDetail";
import IntroDetailRender from "./components/IntroDetail";
import PolicyRender from "./components/Policy";
import SidebarRender from "./components/Sidebar";
import MobileSidebar from "./components/MobileSidebar";
import { commonAxiosPostRequest } from "utils/request";
import { getPureArray } from "utils/util";

export interface ListingProductPageProps {
	className?: string;
	isPreviewMode?: boolean;
}

export type TSkuTypes = {
	grounpId: string;
	propertyId: string;
	propertyName: string;
	typeName: string;
}

export interface ISku {
	id: string;
	productId: string;
	name: string;
	nameEn: string;
	nameHk: string;
	stocks: number;
	sellingPrice: number;
	pictureUrl: string;
	skusTypes: TSkuTypes[];
	attribute: ({ [key: string]: any });
	attributeText?: string;
	quantity?: number;
	totalPrice?: string | number;
};

export type TSkuAttribute = {
	id: string;
	name: string;
	nameEn: string;
	nameHk: string;
	propertyList: ({
		id: string;
		name: string;
		selected?: boolean;
		disabled?: boolean;
	})[];
};
export interface IProductInfo {
	id: string;
	name: string;
	nameEn: string;
	nameHk: string;
	intro: string;
	introEn: string;
	introHk: string;
	pictureUrls: string[];
	detailContent: string;
	detailContentEn: string;
	detailContentHk: string;
	sellingPrice: number;
	skuAttributeList: TSkuAttribute[];
	skus: ISku[];
	salesPolicy: string;
}

const ProductDetailPage: FC<ListingProductPageProps> = ({ className = "", isPreviewMode }) => {
	const { id: productId } = useParams() as any;
	const { t } = useTranslation();
	const history = useHistory();
	const { language } = i18n;

	// 当前商品信息
	const [productInfo, setProductInfo] = useState<IProductInfo | null>(null);
	// 页面加载状态：0/1/2
	const [loadingState, setLoadingState] = useState<0 | 1 | 2>(0);

	useEffect(() => {
		handleQueryProductDetail();
	}, []);

	// 获取商品详情
	const handleQueryProductDetail = async () => {
		setLoadingState(1);
		const [error, response] = await commonAxiosPostRequest<IProductInfo, {}>('/universal_mall/api/app/product/detail', {
			productId,
		});
		setLoadingState(2);
		console.log('[商品信息]', response);
		if (response?.id) {
			setProductInfo(response);
		} else {
			console.log('[商品信息获取失败]', error);
		}
	};

	return (
		<div
			className={`nc-ListingStayDetailPage flex flex-col items-center  justify-center ${className}`}
			data-nc-id="ListingStayDetailPage"
		>
			{loadingState === 1 && <Spinner className="pt-24 pb-24 lg:py-32" />}

			{loadingState === 2 && (
				<>
					<header className="container 2xl:px-14 rounded-md sm:rounded-xl mt-[24px]">
						<Banner imageArr={productInfo?.pictureUrls || []} />
					</header>
					<main className="container mt-11 flex ">
						<div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
							{/* 產品介绍 */}
							<IntroDetailRender productInfo={productInfo}></IntroDetailRender>
							{/* 產品推廣 */}
							<PromoDetailRender productInfo={productInfo}></PromoDetailRender>
							{/* 條款及細則 */}
							<PolicyRender productInfo={productInfo}></PolicyRender>
						</div>
						{/* SIDEBAR */}
						<div className="hidden lg:block flex-grow">
							<div className="sticky top-24">
								{
									productInfo ? <SidebarRender productInfo={productInfo}></SidebarRender> : null
								}
							</div>
						</div>
					</main>
					{/* 移动端添加购物车模块 */}
					<MobileSidebar productInfo={productInfo} />
				</>
			)}
		</div>
	);
};

export default ProductDetailPage;
