import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import "./CustomerInfoPage.scss";
import Spinner from "components/Spinner/Spinner";
import { emailRegex, PaymentOptions } from "./constant";
import Select from "shared/Select/Select";
import { getPureArray, safeParse, checkBrowser, isValidPhoneNumber } from "utils/util";
import { CartCacheLocalStorageKey, BuyFormInfoCacheLocalStorageKey } from "utils/constant";
import { commonAxiosPostRequest } from "utils/request";
import OrderReview from "./components/OrderReview";
import ShippingAddress from "./components/ShippingAddress";
import PaymentMethodTab from "./components/PaymentMethodTab";
import PaymeQrCodeRender from "./components/PaymeQrCodeRender";
import CommonDialog from "../../components/Dialog/index";

export interface CustomerInfoProps {
	className?: string;
}

type TPaymentForm = {
	/* 聯絡人姓氏 */
	customerFirstName: string;
	/* 聯絡人名字 */
	customerLastName: string;
	/* 聯絡人手机号 */
	customerPhone: string;
	/* 聯絡人邮箱 */
	customerEmail: string;
	/* 聯絡人收货信息 */
	regionInfo: {
		province: string;
		city: string;
		area?: string;
		address: string;
	},
	/* 支付方式 */
	paymentMethod: "CreditCard";
	/* 渠道 */
	chInfoText: string;
	/* 渠道-其他 */
	chInfoTextOther: string;
}

type TFormValidData = {
	[key in keyof TPaymentForm]?: string;
};

const CustomerInfoPage: FC<CustomerInfoProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	// 购物车商品列表
	const [cartSkuList, setCartSkuList] = useState<any[]>([]);
	// 总价
	const [transcationTotalAmount, setTranscationTotalAmount] = useState<number>(0);
	// 运费
	const [shippingFee, setShippingFee] = useState<number>(0);
	const [loading, setLoading] = useState(false);
	const [successMesage, setSuccessMessage] = useState<any>(undefined);
	/* 表单 */
	const [values, setValues] = useState<TPaymentForm>({
		customerFirstName: "",
		customerLastName: "",
		customerPhone: "",
		customerEmail: "",
		regionInfo: {
			province: '',
			city: '',
			address: '',
		},
		paymentMethod: "CreditCard",
		chInfoText: "",
		chInfoTextOther: "",
	});
	const [formValid, setFormValid] = useState<TFormValidData>({});
	// 弹框展示信息
	const [dialogInfo, setDialogInfo] = useState({
		show: false,
		title: '',
		content: '',
	});
	/* PayMe支付订单信息 */
	const [paymeOrderInfo, setPaymeOrderInfo] = useState({
		qrLink: "",
		orderId: "",
	});
	// 是否为移动端浏览器
	const [isMobile, setIsMobile] = useState(checkBrowser().isMobile);
	// 页面加载状态：0/1/2
	const [loadingState, setLoadingState] = useState<0 | 1>(0);
	const BuyChInfoList = [
		{ label: t('common.select_placeholder'), value: "" },
		{ label: "Facebook / Instagram", value: "Facebook / Instagram" },
		{ label: t('checkout.exhibition'), value: t('checkout.exhibition') },
		{ label: t('checkout.old_customers'), value: t('checkout.old_customers') },
		{ label: "HKTVMALL", value: "HKTVMALL" },
		{ label: t('checkout.search_online'), value: t('checkout.search_online') },
		{ label: t('checkout.introduced_by_friends'), value: t('checkout.introduced_by_friends') },
		{ label: t('checkout.other'), value: t('checkout.other') },
	];


	// 输入框输入change
	const onInputChange = (e: any) => {
		const { name, value } = e.target;
		setValues((prevState) => ({ ...prevState, [name]: value }));
	};

	// Select选择change
	const onSelectChange = (e: any) => {
		const { name, value } = e.target;
		setValues((values) => ({ ...values, [name]: value }));
	};

	// 修改收货地址change
	const onRegionInfoChange = (newRegionInfo: TPaymentForm['regionInfo'], type: string) => {
		setValues((prevState) => ({ ...prevState, regionInfo: newRegionInfo }));
		if (type === 'province') {
			handleGetShippingCost(newRegionInfo.province);
		}
	}

	// 获取运费
	const handleGetShippingCost = async (code: string) => {
		const [error, response] = await commonAxiosPostRequest<{ expressFee: number }, {}>('/universal_mall/api/app/product/getShippingCost', {
			customerRegionProvinceCode: code,
		});
		const price = response?.expressFee;
		if (error || typeof price !== 'number') {
			setDialogInfo((prev) => ({ ...prev, show: true, title: '提示', content: error?.message || '運費查詢失敗，請稍後重試' }))
		} else {
			setShippingFee(price);
		}
	}

	// 创建订单
	const onSubmitButtonClick = async () => {
		console.log('[onSubmitButtonClick]', values);
		window.localStorage.setItem(BuyFormInfoCacheLocalStorageKey, JSON.stringify(values));
		if (loadingState === 1) {
			return;
		}
		const __formValid: TFormValidData = {
			customerFirstName: !values.customerFirstName ? t('checkout.first_name_input_error') : '',
			customerLastName: !values.customerLastName ? t('checkout.last_name_input_error') : '',
			customerPhone: !isValidPhoneNumber(values.customerPhone) ? t('checkout.phone_input_error') : '',
			regionInfo: (!values.regionInfo.province || !values.regionInfo.city || !values.regionInfo.address) ? t('checkout.address_input_error') : '',
		};
		setFormValid(__formValid);
		if (Object.keys(__formValid).filter((key) => __formValid[key as keyof TFormValidData]).length > 0) {
			setDialogInfo((prev) => ({ ...prev, show: true, title: t('checkout.form_input_error'), content: Object.values(__formValid).filter(n => n)[0] }));
			return;
		}
		setLoadingState(1);
		const [error, response] = await commonAxiosPostRequest<any, {}>('/universal_mall/api/app/product/createOrder', {
			...values,
			skuList: cartSkuList.reduce((p, v) => {
				const list = v.skuList.map((n: any) => ({ productId: v.productId, id: n.id, quantity: n.quantity })).filter((n: any) => n.quantity > 0);
				return [...p, ...list];
			}, []),
			customerRegionProvinceCode: values.regionInfo.province,
			customerRegionCityCode: values.regionInfo.city,
			customerAddress: values.regionInfo?.address || '',
			chInfoText: values.chInfoText === t('checkout.other') ? values.chInfoTextOther : values.chInfoText,
			customerPostalCode: "999077", // TODO
		});
		setLoadingState(0);
		if (!error && response?.orderId) {
			const { orderId, paymentMethod, data } = response;
			if (paymentMethod === 'PayMe') {
				const { webLink } = data;
				// Payme支付方式则是：PC端生成支付二维码、Mobile则跳转去支付链接
				if (isMobile) {
					window.location.replace(webLink);
				} else {
					setPaymeOrderInfo({ qrLink: webLink, orderId });
				}
			} else {
				const paymentFormBoxDom = document.querySelector("#payment-form");
				if (paymentFormBoxDom) {
					paymentFormBoxDom.innerHTML = data.formHtml;
					setTimeout(() => {
						const paymentFormDom = document.querySelector("#payment-form form");
						// @ts-ignore
						paymentFormDom.submit();
					}, 0);
				}
			}
		} else {
			setDialogInfo((prev) => ({ ...prev, show: true, title: '訂單建立失敗', content: error?.message || '系統繁忙，請稍後再試' }));
		}
	}

	// 初始化获取缓存中的购物车商品sku列表
	const handleGetCartSkuList = async () => {
		let cachedCartSkuList: any[] = safeParse(window.localStorage.getItem(CartCacheLocalStorageKey), []);
		cachedCartSkuList = cachedCartSkuList.map(item => ({
			...item,
			skuList: item.skuList.filter((sku: any) => sku.quantity > 0),
		})).filter(item => item.skuList.length > 0);
		const [error, response] = await commonAxiosPostRequest<{ list: any[], transcationTotalAmount: number, shippingFeeText: string }, {}>('/universal_mall/api/app/product/cart', {
			cartSkuList: cachedCartSkuList.map(item => ({
				productId: item.productId,
				skuList: item.skuList.map((sku: any) => ({ id: sku.id, quantity: sku.quantity }))
			}))
		});
		const list = getPureArray<any>(response?.list);
		const total = response?.transcationTotalAmount || 0;
		window.localStorage.setItem(CartCacheLocalStorageKey, JSON.stringify(list));
		setCartSkuList(list);
		setTranscationTotalAmount(total);
	}

	// 初始化表单
	const handleInitForm = () => {
		const localFormData = safeParse(window.localStorage.getItem(BuyFormInfoCacheLocalStorageKey), {});
		if (Object.keys(localFormData).length > 0) {
			setValues({
				customerFirstName: localFormData.customerFirstName || "",
				customerLastName: localFormData.customerLastName || "",
				customerPhone: localFormData.customerPhone || "",
				customerEmail: localFormData.customerEmail || "",
				regionInfo: {
					province: localFormData?.regionInfo?.province || "",
					city: localFormData?.regionInfo?.city || "",
					address: localFormData?.regionInfo?.address || "",
				},
				paymentMethod: localFormData.paymentMethod || "CreditCard",
				chInfoText: localFormData.chInfoText || "",
				chInfoTextOther: localFormData.chInfoTextOther || "",
			});
		}
	}

	useEffect(() => {
		handleGetCartSkuList();
		handleInitForm();
	}, []);

	const renderMain = () => {
		return (
			<>
				<div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
					<h2 className="text-3xl lg:text-4xl font-semibold">{t('checkout.settlement')}</h2>
					<h3 className="text-2xl font-semibold">{t('checkout.information_input_tip')}</h3>
					<div className="border-b border-neutral-200 dark:border-neutral-700"></div>
					<div>
						<h3 className="text-2xl font-semibold">{t('checkout.payment_way_tip')}:</h3>
						<div className="mt-6">
							<Tab.Group>
								<PaymentMethodTab />
								<h3 className="text-2xl font-semibold mt-6">** {t('checkout.phone_input_tip')} **</h3>
								<div className="w-100 border-b border-neutral-200 my-5"></div>
								<Tab.Panels>
									<Tab.Panel className="space-y-5">
										<div className="space-y-1">
											<Label>{t('checkout.last_name')}</Label>
											<Input
												type="text"
												name="customerLastName"
												value={values.customerLastName}
												onChange={onInputChange}
												placeholder={t('checkout.last_name_input_error')}
											/>
										</div>
										<div className="space-y-1">
											<Label>{t('checkout.first_name')}</Label>
											<Input
												type="text"
												name="customerFirstName"
												value={values.customerFirstName}
												onChange={onInputChange}
												placeholder={t('checkout.first_name_input_error')}
											/>
										</div>
										<div className="space-y-1">
											<Label>{t('checkout.email')}</Label>
											<Input
												type="email"
												name="customerEmail"
												value={values.customerEmail}
												onChange={onInputChange}
												placeholder={t('checkout.email_input_error')}
											/>
										</div>
										<div className="space-y-1">
											<Label>{t('checkout.phone')}</Label>
											<Input
												name="customerPhone"
												value={values.customerPhone}
												onChange={onInputChange}
												placeholder={t('checkout.phone_input_error')}
											/>
										</div>
										<div className="border-b border-neutral-200 dark:border-neutral-700 pt-[8px]"></div>
										<div className="space-y-1">
											<Label>{t('checkout.address')}</Label>
											<ShippingAddress regionInfo={values.regionInfo} onChange={onRegionInfoChange}></ShippingAddress>
										</div>
										<div className="space-y-1">
											<Label>{t('checkout.chinfo')}</Label>
											<div className="flex mt-1.5">
												<Select name="chInfoText" onChange={onSelectChange} value={values.chInfoText}>
													{BuyChInfoList.map((item: any) => {
														return (<option value={item.value} key={item.value}>{item.label}</option>);
													})}
												</Select>
												{values.chInfoText === t('checkout.other')
													? <Input name="chInfoTextOther" value={values.chInfoTextOther} onChange={onInputChange} placeholder={t('checkout.please_note')} />
													: null}
											</div>
										</div>
										<div className="border-b border-neutral-200 dark:border-neutral-700 pt-[8px]"></div>
										<div className="space-y-1">
											<Label>{"付款方式"}</Label>
											<Select className="mt-1.5" value={values.paymentMethod} name="paymentMethod" onChange={onSelectChange}>
												{PaymentOptions.map((point: any) => {
													return (<option value={point.value} key={point.value}>{point.label}</option>);
												})}
											</Select>
										</div>
										<div className="space-y-1">
											<>
												<Label>{t('checkout.submit_step_1')}</Label>
												<br />
												<Label>{t('checkout.submit_step_2')}</Label>
												<br />
												<Label>{t('checkout.submit_step_3')}</Label>
												<br />
												{/* <Label>{"4. 將出現一個二維碼並通過 PayMe 掃描該二維碼"}</Label>
													<br /> */}
											</>
										</div>
										{paymeOrderInfo.qrLink ? <PaymeQrCodeRender {...paymeOrderInfo} /> : null}
									</Tab.Panel>
								</Tab.Panels>
							</Tab.Group>
						</div>
					</div>
					<CommonDialog
						{...dialogInfo}
						onClose={() => setDialogInfo((prev) => ({ ...prev, show: false }))}
					/>
					<div className="submitButtonContainer">
						<ButtonSecondary href={"/cart"}>{t('checkout.back_cart')}</ButtonSecondary>
						<ButtonPrimary type="submit" className={"ml-4"} loading={loadingState === 1} onClick={onSubmitButtonClick}>{t('checkout.submit')}</ButtonPrimary>
					</div>
				</div>
			</>
		);
	};

	return (
		<main className="container mt-11 mb-24 lg:mb-32 flex justify-center lg:flex-row">
			{loading === false && successMesage === undefined && (
				<main className="mt-11 flex">
					<div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
						{renderMain()}
						<div id="payment-form"></div>
					</div>
					<div className="hidden lg:block flex-grow">
						<div className="sticky top-24">
							<OrderReview
								transcationTotalAmount={transcationTotalAmount}
								shippingFee={shippingFee}
								cartSkuList={cartSkuList}
							/>
						</div>
					</div>
				</main>
			)}
			{loading === true && <Spinner className="pt-24 pb-24 lg:py-32" />}
		</main>
	);
};

export default CustomerInfoPage;
