// const isProd = process.env.REACT_APP_STAGE !== 'development';
const isProd = true;

// 接口请求地址
export const url = isProd ? window.location.origin : "http://127.0.0.1:7013";
// export const url = 'https://kamishop.co'

// 接口请求地址
export const RequestBaseUrl = url;

// token缓存key
export const TokenLocalStorageKey = 'Mall-Auth-Token';

// 购物车缓存key
export const CartCacheLocalStorageKey = 'MallCartCacheIdentifier';

// 购物表单信息缓存key
export const BuyFormInfoCacheLocalStorageKey = 'BuyFormInfoCacheIdentifier';