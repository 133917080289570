import translationEN from "./en-en.json";
import translationZHCN from "./zh-cn.json";
import translationZHHK from "./zh-hk.json";

export const resources = {
    "zh-CN": {
        translation: translationZHCN
    },
    "en": {
        translation: translationEN
    },
    "zh-HK": {
        translation: translationZHHK
    }
}