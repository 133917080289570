import React, { FC, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { AuthContext } from "context/authenticate";
import { getPureArray, getKeyByLang } from "utils/util";
import { IProductInfo } from "../index";


export interface ComponentProps {
    className?: string;
    productInfo: IProductInfo | null;
};

const PolicyRender: FC<ComponentProps> = ({ className = "", productInfo }) => {
    const { t } = useTranslation();
    const { configInfo } = useContext(AuthContext);
    const { language } = i18n;

    // @ts-ignore
    const privacyPolicyContent = configInfo?.[getKeyByLang(language, 'privacyPolicyContent')] || configInfo?.privacyPolicyContent || '';

    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">{t('product.terms_and_conditions')}：</h2>
            <div className="w-100 border-b border-neutral-200 dark:border-neutral-700" />
            <div className="block mt-3 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={{ __html: privacyPolicyContent }}></div>
        </div>
    );
};

export default PolicyRender;